import * as JsSearch from "js-search";

export function searchItemInObject(listData, seek) {


    let data = Object.assign([], listData);


    let search = new JsSearch.Search('id');

    search.addIndex('name');

    search.addDocuments(data);

    return search.search(seek);


}

export function setItemOfHiddenFieldIfExistsInOptions(value, options, data) {


    for (let i = 0; i < options.length; i++) {

        if (options[i].value === value) {


            let dataObj = Object.assign([], data);


            let search = new JsSearch.Search('id');

            search.addIndex('name');

            search.addDocuments(dataObj);

            return search.search(value)[0].id;


        } else {

            return '';
        }
    }

}

export function searchPlayerInObject(listData, seek) {


    let data = Object.assign([], listData);


    let search = new JsSearch.Search('player_id');

    search.addIndex('player_id');

    search.addDocuments(data);

    return search.search(seek);


}